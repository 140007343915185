<template>
  <div class="home">
    <slick ref="slick" class="slickHome hide-mobile" :options="slickOptions">
      <div class="slick-content-home">
        <div
          class="sub-content-slider"
          :style="{
            'background-image':
              'url(' +
              require('../assets/images/papua/image-slider/img-slide-6.jpg') +
              ')',
          }"
        >
          <div class="content-text">
            <span class="d-block font-50 text-white opensans font-weight-6"
              >KEINDAHAN ALAM PAPUA</span
            >
            <span class="d-block font-30 text-white opensans"
              >Papua Bangkit, Mandiri, dan Sejahtera yang Berkeadilan</span
            >
          </div>
        </div>
      </div>
      <div class="slick-content-home">
        <div
          class="sub-content-slider"
          :style="{
            'background-image':
              'url(' +
              require('../assets/images/papua/image-slider/img-slide-5.jpg') +
              ')',
          }"
        >
          <div class="content-text">
            <span class="d-block font-50 text-white opensans font-weight-6"
              >KEINDAHAN ALAM PAPUA</span
            >
            <span class="d-block font-30 text-white opensans"
              >Teluk Youtefa</span
            >
          </div>
        </div>
      </div>
    </slick>
    <div
      class="width-100 overflow-hidden bg-path mt--res-tab-big-180"
      v-bind:class="{ activeScroll: scrollON }"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="2150" height="386.636" viewBox="0 0 2150 386.636" v-bind:svg-inline="''" v-bind:class="'svg-head'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M0 0s427.254 331.663 1019.811 157.381S2150 228.15 2150 228.15v158.486H0z" fill="#fff"/></svg>
    </div>
    <div
      class="
        container-standar
        bg-white
        mt--res-tab-small-140
        pl-10
        pr-10
        pb-9
        mb-10
      "
    >
      <JiwaSummary />
    </div>
    <div
      class="
        container-fluid
        bg-include
        h-800 h-res-tab-big-720
        height-res-tab-small-auto
      "
    >
      <div class="container-standar">
        <ChartSummary />
      </div>
    </div>
    <div
      class="
        container-standar
        mb-300
        mt--res-tab-big-80 mt-res-tab-small-20
        pt-res-tab-small-10
        mb-res-tab-big-150 mb-res-tab-small-80
      "
    >
      <div class="row">
        <div class="col-md-12 text-center mb-80 mb-res-tab-small-20">
          <span class="d-block w-100 font-weight-7 font-35 text-primary"
            >Layanan Kami</span
          >
          <span class="d-block text-grey">Fasilitas Umum dan Layanan</span>
        </div>
        <div class="col-md-6">
          <span class="d-block w-100 font-weight-7 font-30 text-primary"
            >Apa Saja Fasilitas Kami ?</span
          >
          <p class="font-20 font-res-tab-big-18 mt-10 ln-2">
            SIO Papua adalah Aplikasi penjaring data kampung dan kelurahan di
            seluruh wilayah Papua yang berfungsi untuk meningkatkan kualitas
            pengelolaan data dan informasi dasar kampung sehingga dapat
            mendukung administrasi pemerintahan, perencanaan pembangunan,
            peningkatan layanan dasar, dan pengembangan kegiatan ekonomi
            kampung.
          </p>
        </div>
        <div class="col-md-6 bg-services">
          <img src="@/assets/images/papua/icons/icon-service.svg" alt="" />
        </div>
      </div>
    </div>

    <div class="container-standar mb-5">
      <div class="row">
        <div class="col-md-12 text-center mb-80">
          <span class="d-block w-100 font-weight-7 font-35 text-primary"
            >Berita SIO Papua</span
          >
          <span class="d-block text-grey"
            >Berita dan Article Seputar Papua</span
          >
        </div>
        <div class="news">
          <b-row>
            <b-col
              cols="12"
              lg="4"
              md="6"
              sm="12"
              v-for="(berita, index) in news"
              :key="index"
            >
              <b-link :to="'/berita/' + berita.slug">
                <b-card
                  border-variant="transparent"
                  class="suggest-img"
                  v-b-hover="handleHover"
                  @mouseover="mouseOver(index)"
                  :class="hover && i == index ? 'shadow p-0' : 'p-0'"
                  :style="
                    hover && i == index
                      ? 'transform: scale(1.1); transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1); border-radius: 20px;'
                      : 'border-0'
                  "
                >
                  <b-row>
                    <b-col cols="12">
                      <b-img
                        style="
                          border-radius: 20px;
                          height: 300px;
                          width: 100%;
                          object-fit: cover;
                        "
                        :src="berita.picture_path"
                      ></b-img>
                      <b-badge class="my-3" href="#" variant="primary"
                        >Category</b-badge
                      >
                      - <span>{{ berita.created }}</span> <br />
                      <span class="font-17 font-weight-7">{{
                        berita.title | shotDescription(50)
                      }}</span>
                      <br />
                      <p>{{ berita.content | shotDescription(100) }}</p>
                      <b-avatar
                        src="@/assets/images/avatar.png"
                        size="2rem"
                        class="mt-3 mr-2"
                      ></b-avatar>
                      <span class="font-16 font-weight-4"> Admin</span><br />
                    </b-col>
                  </b-row>
                </b-card>
              </b-link>
            </b-col>
          </b-row>
          <!-- <ul class="news-sub">
                <li  v-for="(berita, index) in news" :key="index"> 
                     <router-link :to="`berita/${berita.id}`" v-bind:key="index">
                  <div class="shadow overflow-hidden  card border-radius-10"  >
                       <div class="img-content h-res-tab-big-200 ">
                         <img
                            :src="berita.picture_path"
                            alt="image"
                          />
                        <div class="title">
                          <span class="text-white font-20 font-res-tab-big-16 width-70 w-res-tab-big-100">{{berita.title }}</span>
                        </div>
                       </div>
                       <div class="content pad-10">
                          <p class="text-grey">{{ berita.content | shotDescription }}</p>
                          <span class="d-block w-100 text-primary font-weight-5 mt-5">{{ berita.publish_date }}</span>
                       </div> 
                  </div> 
                   </router-link>
                </li> 
              </ul> -->
        </div>
        <div
          class="
            flex
            width-100
            justify-center
            text-center
            mt-50
            pb-res-tab-small-40
          "
        >
          <span
            @click.prevent="routerPush('/berita')"
            class="
              d-block
              width-30
              w-res-100 w-res-tab-small-80
              text-center
              cursor-pointer
              border-2
              text-primary
              font-weight-6 font-18
              pad-10
              bc-primary
              border-radius-100
              radius-100
            "
            >Lihat Semua
            <img
              class="d-inline"
              src="@/assets/images/papua/icons/arrow-right.svg"
              alt=""
            />
          </span>
        </div>
      </div>
    </div>
    <div
      class="
        container-fluid
        overflow-hidden
        bg-path-2
        height-res-tab-small-auto
        h-800 h-res-tab-big-650
      "
    >
      <div class="container-standar mt-res-tab-small-20 mt-180">
        <div class="row">
          <div class="col-md-12 mb-20 pad-10 ml-10">
            <span class="d-block w-100 font-weight-7 font-35 text-white"
              >Aplikasi SIO Papua</span
            >
            <span class="d-block font-25 text-white"
              >Aplikasi Edukasi dan Data Academy</span
            >
          </div>
          <div class="col-md-6">
            <div class="width-100 pad-10">
              <img
                class="w-100 cursor-pointer"
                src="@/assets/images/papua/misc/trigerVideo.png"
                alt=""
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="width-100 pad-10">
              <span class="text-white mb-10 d-block w-100 font-28 font-weight-6"
                >Tentang Aplikasi</span
              >
              <p class="text-white width-80 font-20">
                Aplikasi SIO Papua terdiri dari installer dan tata cara untuk
                mengunakan aplikasi sesuai dengan SOP yang sudah dibuat :
              </p>
            </div>
            <br />
            <router-link to="/bantuan/dokumentasi">
              <div class="width-100 flex justify-center text-center">
                <span
                  class="
                    cursor-pointer
                    text-warning
                    w-res-tab-small-80 w-res-90
                    border-radius-8
                    mt-40
                    shadow
                    width-60
                    pad-10
                    text-center
                    font-weight-6 font-20
                    bg-white
                  "
                  >Dokumentasi</span
                >
              </div>
            </router-link>
            <!-- <div
              class="
                width-100
                flex
                justify-center
                text-center
                pb-res-tab-small-40
              "
            >
              <span
                class="
                  cursor-pointer
                  text-warning
                  w-res-tab-small-80 w-res-90
                  border-radius-8
                  mt-20
                  shadow
                  width-60
                  pad-10
                  text-center
                  font-weight-6 font-20
                  bg-white
                "
                >Dokumen Manual</span
              >
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="container-standar mt-180 mt-res-tab-small-20 mb-180">
      <div class="width-100 text-center mb-40">
        <span
          class="
            d-block
            w-100
            mt-res-tab-small-20
            font-weight-7 font-35
            text-primary
          "
          >Statistic Progress Data</span
        >
        <span class="d-block text-grey">Sinkronisasi 10 data teratas</span>
      </div>
      <div class="width-100 home-nav-lit">
        <b-tabs pills fill>
          <b-tab title="Kependudukan" active>
            <div class="width-100 mt-40 pad-10">
              <div class="row">
                <div class="col-md-4">
                  <div class="card pb-10 shadow">
                    <span
                      class="
                        text-primary text-center
                        mb-10
                        font-24 font-weight-7
                        pad-10
                        mt-20
                        d-block
                        width-100
                      "
                    >
                      Grafik Penduduk
                    </span>
                    <vue-apex-charts
                      type="donut"
                      height="350"
                      :options="donutChart1.chartOptions"
                      :series="donutChart1.series"
                    ></vue-apex-charts>
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="card h-100 shadow">
                    <span
                      class="
                        text-primary text-center
                        font-24 font-weight-7
                        pad-10
                        mt-20
                        d-block
                        width-100
                      "
                    >
                      Data Penduduk
                    </span>
                    <div class="width-100 pt-10 pb-10 pl-10 pr-10">
                      <div class="flex width-100 mb-10">
                        <span
                          class="
                            text-left
                            d-block
                            text-primary
                            width-50
                            font-weight-6
                          "
                          >Penduduk Laki-Laki</span
                        >
                        <span
                          class="
                            text-right
                            d-block
                            text-warning
                            width-50
                            font-weight-6
                          "
                          >{{
                            data_penduduk.pria.semua | convertToRupiah
                          }}
                          Jiwa</span
                        >
                      </div>
                      <div class="flex width-100 mb-10">
                        <span
                          class="
                            text-left
                            d-block
                            text-primary
                            width-50
                            font-weight-6
                          "
                          >Penduduk Perempuan</span
                        >
                        <span
                          class="
                            text-right
                            d-block
                            text-warning
                            width-50
                            font-weight-6
                          "
                          >{{
                            data_penduduk.wanita.semua | convertToRupiah
                          }}
                          Jiwa</span
                        >
                      </div>
                      <div class="flex width-100 mb-10">
                        <span
                          class="
                            text-left
                            d-block
                            text-primary
                            width-50
                            font-weight-6
                          "
                          >Total KK</span
                        >
                        <span
                          class="
                            text-right
                            d-block
                            text-warning
                            width-50
                            font-weight-6
                          "
                          >{{
                            data_penduduk.kartu_keluarga.semua | convertToRupiah
                          }}
                          Keluarga</span
                        >
                      </div>
                      <div class="flex width-100 mb-10">
                        <span
                          class="
                            text-left
                            d-block
                            text-primary
                            width-50
                            font-weight-6
                          "
                          >Total OAP</span
                        >
                        <span
                          class="
                            text-right
                            d-block
                            text-warning
                            width-50
                            font-weight-6
                          "
                          >{{
                            data_penduduk.jiwa.oap | convertToRupiah
                          }}
                          Jiwa</span
                        >
                      </div>
                      <div class="flex width-100 mb-10">
                        <span
                          class="
                            text-left
                            d-block
                            text-primary
                            width-50
                            font-weight-6
                          "
                          >Total Non OAP</span
                        >
                        <span
                          class="
                            text-right
                            d-block
                            text-warning
                            width-50
                            font-weight-6
                          "
                          >{{
                            (data_penduduk.jiwa.semua - data_penduduk.jiwa.oap)
                              | convertToRupiah
                          }}
                          Jiwa</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab title="Kesehatan">
            <div class="width-100 text-center mt-40 pad-10">
              <div class="row">
                <div class="col-md-6">
                  <div class="card pb-10 shadow">
                    <span
                      class="
                        text-primary text-center
                        mb-10
                        font-24 font-weight-7
                        pad-10
                        mt-20
                        d-block
                        width-100
                      "
                    >
                      Data Sumber dan Cara Mendapatkan Air Minum
                    </span>
                    <kesehatan />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card h-100 shadow">
                    <span
                      class="
                        text-primary text-center
                        font-24 font-weight-7
                        pad-10
                        mt-20
                        d-block
                        width-100
                      "
                    >
                      Data Jiwa OAP dan Non OAP
                    </span>
                    <div class="width-100 pt-10 pb-10 pl-10 pr-10">
                      <div class="flex width-100 mb-10">
                        <span
                          class="
                            text-left
                            d-block
                            text-primary
                            width-50
                            font-weight-6
                          "
                          >Total OAP</span
                        >
                        <!-- <span
                          class="
                            text-right
                            d-block
                            text-warning
                            width-50
                            font-weight-6
                          "
                          >{{ data_penduduk.jiwa.oap | convertToRupiah }} Jiwa</span
                        > -->
                      </div>
                      <div class="flex width-100 mb-10">
                        <span
                          class="
                            text-left
                            d-block
                            text-primary
                            width-50
                            font-weight-6
                          "
                          >Total Non OAP</span
                        >
                        <span
                          class="
                            text-right
                            d-block
                            text-warning
                            width-50
                            font-weight-6
                          "
                          >{{
                            (data_penduduk.jiwa.semua - data_penduduk.jiwa.oap)
                              | convertToRupiah
                          }}
                          Jiwa</span
                        >
                      </div>
                      <div class="flex width-100 mb-10">
                        <span
                          class="
                            text-left
                            d-block
                            text-primary
                            width-50
                            font-weight-6
                          "
                          >Total Semua</span
                        >
                        <span
                          class="
                            text-right
                            d-block
                            text-warning
                            width-50
                            font-weight-6
                          "
                          >{{
                            data_penduduk.jiwa.semua | convertToRupiah
                          }}
                          Jiwa</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab title="Pendidikan">
            <div class="width-100 text-center mt-40 pad-10">
              <div class="row">
                <div class="col-md-12">
                  <div class="card pb-10 shadow">
                    <span
                      class="
                        text-primary text-center
                        mb-10
                        font-24 font-weight-7
                        pad-10
                        mt-20
                        d-block
                        width-100
                      "
                    >
                      Data Tingkat Pendidikan Terakhir
                    </span>
                    <Pendidikan />
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab title="Bansos">
            <div class="width-100 text-center mt-40 pad-10">
              <div class="row">
                <div class="col-md-12">
                  <div class="card pb-10 shadow">
                    <span
                      class="
                        text-primary text-center
                        mb-10
                        font-24 font-weight-7
                        pad-10
                        mt-20
                        d-block
                        width-100
                      "
                    >
                      Data Bantuan Sosial Tahun 2022
                    </span>
                    <Bansos />
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import JiwaSummary from "./components/JiwaSummary";
import ChartSummary from "./components/ChartSummary";
import VueApexCharts from "vue-apexcharts";
import Slick from "vue-slick";
import Kesehatan from "./components/Kesehatan";
import Pendidikan from "./components/Pendidikan";
import Bansos from "./pages/data-pokok/data-kependudukan/components/bansos";
export default {
  name: "home",
  components: {
    JiwaSummary,
    ChartSummary,
    Slick,
    VueApexCharts,
    Kesehatan,
    Pendidikan,
    Bansos,
  },
  data() {
    return {
      windowTop: 0,
      scrollON: false,
      total_pria: 0,
      total_perempuan: 0,
      total_kk: 0,
      total_jiwa: 0,
      total_penduduk_pria: 0,
      data_penduduk: {
        jiwa: {
          oap: 0,
          semua: 0,
        },
        kartu_keluarga: {
          oap: 0,
          semua: 0,
        },
        pria: {
          oap: 0,
          semua: 0,
        },
        wanita: {
          oap: 0,
          semua: 0,
        },
      },
      news: [],
      allnews: null,
      total_penduduk_perempuan: 0,
      selectYear: {
        penduduk: "all",
        oap: "all",
      },
      yearOptions: [
        {
          text: "Tahun 2020-2022",
          value: "all",
        },
        {
          text: "Tahun 2020",
          value: "2020",
        },
        {
          text: "Tahun 2021",
          value: "2021",
        },
        {
          text: "Tahun 2022",
          value: "2022",
        },
      ],
      slickOptions: {
        autoplay: true,
        autoplaySpeed: 5000,
        dots: false,
        arrows: false,
        // Any other options that can be got from plugin documentation
      },
      donutChart1: {
        chartOptions: {
          legend: {
            show: false,
          },
          labels: ["Perempuan", "Pria"],
          colors: ["#1A598C", "#4AAFFF"],
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    show: true,
                    fontSize: "22px",
                    fontFamily: "Rubik",
                    color: "#dfsda",
                    offsetY: -10,
                  },
                  value: {
                    show: true,
                    fontSize: "16px",
                    fontFamily: "Roboto, sans-serif",
                    color: undefined,
                    offsetY: 16,
                    formatter: function (val) {
                      return val;
                    },
                  },
                  total: {
                    show: true,
                    label: "Total",
                    color: "#373d3f",
                    formatter: function (w) {
                      return w.globals.seriesTotals.reduce((a, b) => {
                        return a + b;
                      }, 0);
                    },
                  },
                },
              },
            },
          },
        },
        series: [444105, 493353],
      },
      hover: false,
      i: null,
    };
  },

  mounted() {
    window.addEventListener("scroll", this.onScroll);
    // this.getslideData();
    this.$store.commit("SET_BG", false);
    this.getTotalJenisPenduduk();
    this.getTotalPenduduk();
    this.getBerita();
  },
  methods: {
    routerPush(newVal) {
      this.$router.push(newVal);
    },
    getTotalPenduduk() {
      this.$axios.get("/v1/sensus/data-penduduk").then((response) => {
        let result = response.data;
        this.total_penduduk_pria = result.total_pria;
        this.total_penduduk_perempuan = result.total_wanita;
      });
    },
    getTotalJenisPenduduk() {
      this.$axios.get("/v1/sensus/jiwa-kk-pria-wanita").then((response) => {
        let result = response.data;
        this.data_penduduk = result;
        this.total_pria = result.pria.semua;
        this.total_perempuan = result.wanita.semua;
        this.total_jiwa = result.jiwa.semua;
        this.total_kk = result.kartu_keluarga.semua;
      });
    },
    getBerita() {
      this.$axios.get("/news").then((response) => {
        //  var allnews = response.data
        console.log(response);
        this.news = response.data.data.filter((value, index) => index < 3);
      });
    },

    onScroll() {
      this.windowTop = window.top.scrollY;
      if (this.windowTop <= 0) {
        this.scrollON = false;
      } else if (this.windowTop >= 10) {
        this.scrollON = true;
      }
    },
    handleAfterChange(slick, currentSlide) {
      // console.log( currentSlide, slick);
      if (currentSlide.currentSlide == 0) {
        this.slideOff = true;
      } else {
        this.slideOff = false;
      }
    },
    handleHover(isHovered) {
      if (isHovered) {
        this.hover = true;
      } else {
        this.hover = false;
      }
    },
    mouseOver(i) {
      this.i = i;
    },
  },
};
</script>
